// Chakra imports
import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
  useColorMode,
  Image,
  useColorModeValue,
  useToast,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import Fetch from "../../common/fetch";
import { FormC } from "../../common/validate";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import InputField from "components/input";
import { SelectMultiple } from "components/input";
import AddIcon from "../../assets/svg/add_icon.svg";
import SettingIcon from "../../assets/svg/ic_setting.svg";
import ExtractIcon from "../../assets/svg/ic_extract.svg";
import MoreIcon from "../../assets/svg/ic_more.svg";
import FiltersIcon from "../../assets/svg/Filters.svg";
import DummyImg from "../../assets/img/dummy_img.png";
import InfoCard from "components/InfoCard";

function NewCatalog(props) {
  const { colorMode } = useColorMode();
  const history = useHistory();
  const catalogueId = props.match.params?.id;
  const addForm = catalogueId === 'add'
  const [isLoading, setisLoading] = useState(false)

  const [state, setState] = useState({
    object_name: '',
    template: '',
    categories: [],
    attributes: [],
  })
  const toast = useToast()
  const [category, setCategory] = useState([])
  const [json_data, setJson_data] = useState({})
  const [showParent, setShowParent] = useState(false)
  const [wineFiled, setWineFiled] = useState([])
  const [templateAttr, setTemplateAttr] = useState([])

  const onSubmit = () => {
    // setisLoading(true)

    const categoryId = state.categories.map(item => item.value);

    const requestBody = {
      object_name: state.object_name,
      template: state.template,
      categories: state.categories,
      attributes: state.attributes
    };
    Fetch(`dashboard/product-object/${addForm ? '' : catalogueId + '/'}`, requestBody, {
      method: addForm ? "post" : 'patch',
    }).then((res) => {
      if (res.status) {
        setisLoading(false)
        toast({
          title: `Catalogue ${addForm ? 'created' : 'updated'}.`,
          description: `We've ${addForm ? 'created' : 'updated'} your catalogue successfully.`,
          status: 'success',
          duration: 3000,
          isClosable: false,
        })
        setTimeout(() => {
          history.push({
            pathname: "/admin/catalog",
          });
        }, 3000)
      } else {
        setisLoading(false)
      }
    });
  };

  // const onChange = (e) => {
  //   const { name, value } = e.target
  //   if (name === 'object_name') {
  //     // if (name === 'object_name' && value > 90) {
  //     setState(prevState => prevState)
  //   }
  //   console.log(name, value, 'value===');
  //   if (name === 'template') {
  //     getAllTemplate(value)
  //   }
  //   setState({
  //     ...state,
  //     [name]: value,
  //   })
  // }
  // const onDynamicChange = (e) => {
  //   const { name, value } = e.target
  //   setState({
  //     ...state,
  //     json_data: {
  //       ...state.json_data,
  //       [name]: value,
  //     }
  //   })
  // }
  const getAllAttr = (value) => {
    Fetch(`dashboard/template/${value}/attributes/`).then(res => {
      if (res.status) {
        setTemplateAttr(res.data)
      }
    })
  }
  const onChange = (e) => {
    const { name, value } = e.target;
    let param = {
      ...state,
      [name]: value,
    }
    if (name === 'template') {
      param = {
        ...param,
        attribute:[]
      }
      setJson_data({})
      getAllAttr(value);
    }
    setState(param);
  };

  const onDynamicChange = (e, items) => {
    const { name, value } = e.target;
    let data = [...state.attributes];
    const itemIndex = data.findIndex(attr => attr.attribute === items.id);

    if (itemIndex !== -1) {
      // Update existing item
      data[itemIndex] = {
        ...data[itemIndex],
        text_value: value,
        float_value: items.field_type === 'number' ? +value : 0,
      };
    } else {
      // Add new item
      data.push({
        text_value: value,
        float_value: items.field_type === 'number' ? +value : 0,
        attribute: items.id,
      });
    }
    setJson_data({
      ...json_data,
      [name]: value,
    })
    setState({
      ...state,
      attributes: data,
    });
  }
  useEffect(() => {
    if (!addForm) {
      Fetch(`dashboard/product-object/${catalogueId}/`).then((res) => {
        if (res.status) {
          const { data } = res
          let json = {}
          data.attributes.map(items => {
            json = {
              ...json,
              [items.attribute]: items.text_value
            }
          })
          setState({
            object_name: data.object_name,
            template: data.template,
            categories: data.categories.map(items => items.id),
            attributes: data.attributes,
            id: data.id,
          })
          setJson_data(json)
          getAllAttr(data.template)
        }
      });
    }
    getCategory();
    getAllTemplate();
  }, []);
  const getCategory = () => {
    Fetch(`dashboard/category`).then((res) => {
      if (res.status) {
        setCategory(res.data.map(items => { return { label: items.name, value: items.id } }))
      }
    });
  }
  const getAllTemplate = () => {
    Fetch(`dashboard/template`).then((res) => {
      if (res.status) {
        setWineFiled(res.data)
      }
    });
  }
  const { handleSubmit, errors, handleNewError, } = FormC({
    values: { ...state },
    onSubmit,
  });

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px", lg: "80px" }}
      bgColor={"white"}
    >
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "50px",
          }}
        >
          <Stack direction="row" alignItems={'center'} width={"fit-content"}>
            <Badge
              fontSize={"16px"}
              bgColor={"#f8f8f8"}
              borderRadius={'8px'}
              padding={'4px 10px'}
              textColor={"#898989"}
              lineHeight={"32px"}
              mr={"10px"}
            >
              Catalog
            </Badge>
            <Text fontWeight={"bold"} fontSize={"20px"}>
              {!addForm ? state.name : "Add New Item"}
            </Text>
          </Stack>
          <Stack direction="row" spacing={4}>
            <NavLink to="/admin/catalog">
              <Button
                variant="outline"
                textColor={"#0584FE"}
                borderColor={"#0584FE"}
              >
                Cancel
              </Button>
            </NavLink>
            <Button isLoading={isLoading} type="submit" colorScheme="blue">
              {!addForm ? "Update" : "Save"} & Close
            </Button>
          </Stack>
        </div>
        <Box
          border="1px"
          borderColor="gray.200"
          p={"15px"}
          mt={"15px"}
          borderRadius={"8px"}
        >
          <Grid templateColumns='repeat(3, 1fr)' gap={3}>
            <GridItem w='100%'>
              <InputField
                label='Object Name'
                size="md"
                borderRadius={"8px"}
                fontSize="sm"
                placeholder="Enter value"
                name="object_name"
                value={state.object_name}
                onChange={onChange}
                backgroundColor={"rgba(222, 222, 222, 0.3)"}
                errorText={errors?.object_name}
              // InputRightElement={
              //   <InputRightElement width="4.5rem">
              //     <Button
              //       h="1.75rem"
              //       size="sm"
              //       color={"rgba(137, 137, 137, 1)"}
              //       backgroundColor={"rgba(248, 248, 248, 1)"}
              //     >
              //       {state.object_name?.length} / 90
              //     </Button>
              //   </InputRightElement>
              // }
              />
              <InputField
                placeholder="Select the content type"
                name="template"
                label='Object template'
                type='select'
                value={state.template}
                onChange={onChange}
                errorText={errors?.template}
                size="md"
                borderRadius={"8px"}
                fontSize="sm"
              >
                {
                  wineFiled?.map((items, key) => <option key={key} value={items.id}>{items.name}</option>)
                }
                {/* <option value={"wine"}>{newVal}</option> */}
              </InputField>

              <SelectMultiple
                value={category.filter(item => state.categories.includes(item.value))}
                errorText={errors?.categories}
                label='Categories'
                placeholder='Select & search Category'
                badgeColor='yellow'
                onChange={(val) => {
                  setState({
                    ...state,
                    categories: val.map(item => item.value),
                  });
                }}
                option={category}
              />
            </GridItem>
            <GridItem w='100%'>
              <Flex justifyContent='space-between'>
                <Menu>
                  <MenuButton _hover={{ bg: "transparent" }} _focus={{ boxShadow: 'unset' }} _active={{ bg: "transparent" }} bg="transparent" as={IconButton}>
                    <Flex>
                      Documents and articles <Image src={FiltersIcon} w={"20px"} />
                    </Flex>
                  </MenuButton>
                  {/* <MenuList>
                    <MenuItem><Image src={AddIcon} w={"20px"} mr='10px' /> Add new article</MenuItem>
                  </MenuList> */}
                </Menu>
                <Menu>
                  <MenuButton _hover={{ bg: "transparent" }} _focus={{ boxShadow: 'unset' }} _active={{ bg: "transparent" }} bg="transparent" as={IconButton}>
                    <Image src={MoreIcon} w={"20px"} />
                  </MenuButton>
                  <MenuList>
                    <MenuItem><Image src={AddIcon} w={"20px"} mr='10px' /> Add new article</MenuItem>
                    <MenuItem><Image src={SettingIcon} w={"20px"} mr='10px' /> Manage object articles</MenuItem>
                    <MenuItem><Image src={ExtractIcon} w={"20px"} mr='10px' /> AI Extract article data</MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
              <Box>
                <InfoCard color='#1E0E62' title='Product Description' text='This wine is an exceptional choice for one that is under $30 and...' />
                <InfoCard color='#AD26B4' title='Article' text='This wine is an exceptional choice for one that is under $30 and...' />
                <InfoCard color='#AD26B4' title='Review' text='This wine is an exceptional choice for one that is under $30 and...' />
              </Box>
            </GridItem>
            <GridItem w='100%'>
              <Flex justifyContent='space-between'>
                <Menu>
                  <MenuButton _hover={{ bg: "transparent" }} _focus={{ boxShadow: 'unset' }} _active={{ bg: "transparent" }} bg="transparent" as={IconButton}>
                    <Flex>
                      Linked media <Image src={FiltersIcon} w={"20px"} />
                    </Flex>
                  </MenuButton>
                  {/* <MenuList>
                    <MenuItem><Image src={AddIcon} w={"20px"} mr='10px' /> Add new article</MenuItem>
                  </MenuList> */}
                </Menu>
                <Menu>
                  <MenuButton _hover={{ bg: "transparent" }} _focus={{ boxShadow: 'unset' }} _active={{ bg: "transparent" }} bg="transparent" as={IconButton}>
                    <Image src={MoreIcon} w={"20px"} />
                  </MenuButton>
                  <MenuList>
                    <MenuItem><Image src={AddIcon} w={"20px"} mr='10px' /> Add new media</MenuItem>
                    <MenuItem><Image src={SettingIcon} w={"20px"} mr='10px' /> Manage object media</MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
              <Grid templateColumns='repeat(2, 1fr)' gap={3}>
                <GridItem w='100%'>
                  <InfoCard image={DummyImg} color='#1E0E62' title='Image' text='Media description here' />
                </GridItem>
                <GridItem w='100%'>
                  <InfoCard image={DummyImg} color='#AD26B4' title='Image' text='Media description here' />
                </GridItem>
                <GridItem w='100%'>
                  <InfoCard image={DummyImg} color='#AD26B4' title='Image' text='Media description here' />
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
        </Box>
        {templateAttr?.length ? <Box
          border="1px"
          borderColor="gray.200"
          p={"15px"}
          mt={"25px"}
          borderRadius={"8px"}
        >
          <Grid templateColumns='repeat(3, 1fr)' gap={3}>
            {
              templateAttr?.map((items, key) =>
                <GridItem w='100%' key={key}>
                  <InputField
                    placeholder={items.label}
                    name={items.id}
                    label={items.label}
                    type={items.field_type}
                    errorText={errors[items.id]}
                    value={json_data[items?.id]}
                    onChange={(e) => onDynamicChange(e, items)}
                    size="md"
                    borderRadius={"8px"}
                    fontSize="sm"
                  >
                    {/* {
                      items?.option.map((items,key)=><option value={items} key={key}></option>)
                    } */}
                  </InputField>
                </GridItem>
              )
            }
          </Grid>
        </Box> : null}
      </form>
    </Flex>
  );
}

export default NewCatalog;
