import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Wrap,
  WrapItem,
  useColorMode,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
// Assets
// Custom Icons
import {
  ArgonLogoDark,
  ArgonLogoLight,
  ChakraLogoDark,
  ChakraLogoLight,
  ProfileIcon,
  SettingsIcon,
} from "components/Icons/Icons";
import Auth from "common/auth";
// Custom Components
import React from "react";
import { FaFoursquare } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import FourDotsIcon from "../../assets/svg/four-dots.svg";
import { FourDotsIco } from "components/Icons/menu";

function HeaderLinks(props) {
  const {
    variant,
    children,
    fixed,
    scrolled,
    secondary,
    onOpen,
    ...rest
  } = props;

  const { colorMode } = useColorMode();

  const handleSignOut = () => {
    Auth.removeStorage();
  };

  // Chakra Color Mode
  let navbarIcon =
    fixed && scrolled
      ? useColorModeValue("gray.700", "gray.200")
      : useColorModeValue("white", "gray.200");
  let menuBg = useColorModeValue("white", "navy.800");
  if (secondary) {
    navbarIcon = "white";
  }
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      {/* <div style={{ marginRight: "20px", cursor: "pointer" }}>
        <FourDotsIco />
      </div> */}
      <Menu w="50px">
        <MenuButton>
          <Wrap cursor={"pointer"}>
            <WrapItem>
              <Avatar name="Dan Abrahmov" src="https://bit.ly/dan-abramov" />
            </WrapItem>
          </Wrap>
        </MenuButton>
        <MenuList>
          <MenuItem>View Profile</MenuItem>

          <NavLink to="/auth/signin" minW="100%">
            <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
          </NavLink>
        </MenuList>
      </Menu>
    </Flex>
  );
}

export default HeaderLinks;
