import { FaPencilAlt } from "react-icons/fa";
import {
    Badge,
    Button,
    Flex,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Text,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";
// Custom components
import CardBody from "components/Card/CardBody.js";
import DeleteModal from "components/Modals/DeleteModal";
import Fetch from "../../common/fetch";
import React, { useEffect, useState } from "react";
import { PlusIco } from "components/Icons/menu";
import { useHistory, withRouter } from "react-router-dom";
import { FiltersIco } from "components/Icons/menu";
import { SearchIco } from "components/Icons/Catalog";
import DataTable from "components/DataTable";
import axios from 'axios';


function Template(props) {
    const history = useHistory()
    const color = useColorModeValue("blue.500", "white");
    const textColor = useColorModeValue("gray.700", "white");
    const borderColorTD = useColorModeValue("gray.900", "white");
    const [templateData, setTemplateData] = useState([]);
    const [isLoading, setisLoading] = useState(false);

    const toast = useToast();
    const getTemplateData = () => {
        Fetch("dashboard/template/").then((res) => setTemplateData(res?.data?.map(items => { return { ...items, actions: items } })));
    };

    useEffect(() => {
        getTemplateData();
    }, []);
    const handleDeleteRequest = (id) => {
        Fetch(`dashboard/template/${id}/`, null, { method: "delete" }).then(
            (res) => {
                if (res.status) {
                    getTemplateData();
                    toast({
                        title: "Template Deleted.",
                        description: "We've deleted your template successfully.",
                        status: "success",
                        duration: 3000,
                        isClosable: false,
                    });
                }
            }
        );
    };
    const columns = [
        { accessor: "id", Header: "DATA ID", width: 130 },
        { accessor: "name", Header: "Name", width: 130 },
        { accessor: "description", Header: "Template Description", width: 130 },
        {
            accessor: "actions",
            Header: "Actions",
            width: 130,
            Cell: (cellValues) => {
                return (
                    <Flex>
                        <DeleteModal
                            name={"Delete"}
                            title={"Are you sure you want to delete this template?"}
                            handleDelete={() => handleDeleteRequest(cellValues.value.id)}
                        />
                        <Button
                            variant="outline"
                            color={color}
                            borderColor={color}
                            colorScheme="blue"
                            onClick={() => handleUpdateRequest(cellValues.value.id)}
                        >
                            <FaPencilAlt color={color} />
                        </Button>
                    </Flex>
                );
            },
        },
    ];
    const handleUpdateRequest = (id) => {
        history.push({
            pathname: `/admin/template/${id}`,
        });
    };
    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Flex p="6px 0px 22px 0px" justify={"space-between"}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Catalog
                </Text>
                <Stack direction={"row"} spacing={4}>
                    <InputGroup w={"200px"}>
                        <InputRightElement pointerEvents="none">
                            <SearchIco />
                        </InputRightElement>
                        <Input
                            type="text"
                            placeholder="Search"
                            textColor={"rgba(177, 177, 177, 1)"}
                            fontWeight={400}
                            fontSize={"14px"}
                            lineHeight={"24px"}
                        />
                    </InputGroup>

                    <Button
                        w={"150px"}
                        colorScheme="gray"
                        color={"gray"}
                        fontWeight={"light"}
                        variant="outline"
                    >
                        <Flex justify={"space-between"} minW={"100%"}>
                            <Text
                                fontSize={"14px"}
                                lineHeight={"24px"}
                                textColor={"rgba(0, 0, 0, 1)"}
                            >
                                Filters
                            </Text>
                            <FiltersIco />
                        </Flex>
                    </Button>

                    <Button
                        leftIcon={<PlusIco />}
                        onClick={() => props.history.push("/admin/template/add")}
                        variant="outline"
                        width={"120px"}
                        h={"40px"}
                        borderRadius={"8px"}
                        border={"1px solid #0584FE"}
                        p={"8px 14px 8px 14px"}
                        gap={"10px"}
                        fontWeight={"600"}
                        fontSize={"14px"}
                        lineHeight={"24px"}
                        letterSpacing={"1%"}
                        textAlign={"center"}
                        pl={"15px"}
                        textColor={"rgba(5, 132, 254, 1)"}
                        borderColor={"rgba(5, 132, 254, 1)"}
                    >
                        NEW ITEM
                    </Button>
                </Stack>
            </Flex>
            <CardBody
                border={"1px solid lightgrey"}
                padding={"15px"}
                borderRadius="5px"
                marginBottom="20px"
                p={0}
            >
                <DataTable table={{ columns: columns, rows: templateData }} path="/inbound/asn/create" />
            </CardBody>
        </Flex>
    );
}

export default withRouter(Template);
