import {
  ArrowForwardIcon,
  CheckIcon,
  EmailIcon,
  PhoneIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import {
  Badge,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Catalog from "components/Tables/Catalog";
import Fetch from "../../common/fetch";
import React, { useEffect, useState } from "react";
import { FaDiceThree, FaFileContract } from "react-icons/fa";
import FilterIcon from "../../assets/svg/filter-icon.svg";
import { ActionsIco } from "components/Icons/menu";
import { PlusIco } from "components/Icons/menu";
import { withRouter } from "react-router-dom";
import Category from "components/Tables/Category";

const CategoriesListing = (props) => {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [catagoriesListing, setCategoriesListing] = useState([]);

  const fetchCategories = () => {
    Fetch("dashboard/category/").then((res) => setCategoriesListing(res.data));
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    // <h1>Categories Page</h1>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Flex p="6px 0px 22px 0px" justify={"space-between"}>
        <Text
          fontSize="xl"
          color={textColor}
          fontWeight="bold"
          //   backgroundColor={"yellow"}
        >
          Categories
        </Text>
        {/* <Badge colorScheme="#fdf40800">Categories</Badge> */}
        <Stack direction={"row"} spacing={4}>
          <Button
            leftIcon={<PlusIco />}
            onClick={() => props.history.push("/admin/categories/add")}
            variant="outline"
            width={"120px"}
            h={"40px"}
            borderRadius={"8px"}
            border={"1px solid #0584FE"}
            p={"8px 14px 8px 14px"}
            fontWeight={"600"}
            fontSize={"14px"}
            lineHeight={"24px"}
            textColor={"rgba(5, 132, 254, 1)"}
            borderColor={"rgba(5, 132, 254, 1)"}
          >
            NEW GROUP
          </Button>
          <Button
            rightIcon={<ActionsIco />}
            colorScheme="#0584FE"
            variant="outline"
            width={"120px"}
            height={"40px"}
            borderRadius={"8px"}
            border={"1px solid #0584FE"}
            padding={"8px 14px 8px 14px"}
            gap={"10px"}
            fontWeight={700}
            fontSize={"14px"}
            lineHeight={"24px"}
            letterSpacing={"1%"}
            textAlign={"center"}
            textColor={"rgba(5, 132, 254, 1)"}
            borderColor={"rgba(5, 132, 254, 1)"}
          >
            ACTIONS
          </Button>
        </Stack>
      </Flex>
      <CardBody
        border={"1px solid lightgrey"}
        padding={"15px"}
        borderRadius="5px"
        marginBottom="20px"
        p={0}
      >
        <Table variant="simple" color={textColor}>
          <Thead borderBottom={"1px solid black"}>
            <Tr my=".8rem" pl="0px" color="gray.400">
              <Th borderColor={borderColor} color="gray.400" py={"18px"}>
                NAME
              </Th>
              <Th borderColor={borderColor} color="gray.400" py={"18px"}>
                DESCRIPTION
              </Th>
              <Th borderColor={borderColor} color="gray.400" py={"18px"}>
                ITEMS
              </Th>
              <Th borderColor={borderColor} color="gray.400" py={"18px"}>
                LAST MODIFIED
              </Th>
              <Th borderColor={borderColor} color="gray.400" py={"18px"}>
                ACTIONS
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {/* <Category
              id={1}
              name={"Newest Red Wine"}
              description={"This bottle of red wine is newest"}
              items={"17"}
              last_modified={"2024-03-22 12:42:02 UTC"}
              slug={"4689667"}
              user={"user name"}
              isLast={true}
              key={1}
              fetchCategories={fetchCategories}
            />
            <Category
              id={1}
              name={"Newest Red Wine"}
              description={"This bottle of red wine is newest"}
              items={"17"}
              last_modified={"2024-03-22 12:42:02 UTC"}
              slug={"4689667"}
              user={"user name"}
              isLast={true}
              key={1}
              fetchCategories={fetchCategories}
            />
            <Category
              id={1}
              name={"Newest Red Wine"}
              description={"This bottle of red wine is newest"}
              items={"17"}
              last_modified={"2024-03-22 12:42:02 UTC"}
              slug={"4689667"}
              user={"user name"}
              isLast={true}
              key={1}
              fetchCategories={fetchCategories}
            /> */}
            {catagoriesListing?.map((row, index, arr) => {
              return (
                <Category
                  index={index}
                  id={row.id}
                  name={row.name}
                  description={row.description || "This bottle of red wine"}
                  items={row.items || 17}
                  last_modified={row.last_modified || "2024-00-22 12:42:02 UTC"}
                  slug={row.slug}
                  user={row.user}
                  // isLast={index === arr.length - 1 ? true : false}
                  isLast={true}
                  key={index}
                  fetchCategories={fetchCategories}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Flex>
  );
};

export default withRouter(CategoriesListing);
