import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Box,
    List,
    ListItem,
    Flex,
    Button,
    Image,
    Icon,
    useColorModeValue,
    Checkbox,
    Text,
    useToast,
    FormLabel
} from '@chakra-ui/react'
import Fetch from 'common/fetch';
import { FormC } from 'common/validate';
import InputField from 'components/input'
import { FormText } from 'components/input';
import { useState } from 'react'
import Add from "../../assets/svg/ic_add_option.svg";
import Remove from "../../assets/svg/ic_clear_option.svg";

const AddProperty = ({ isOpen, onClose }) => {
    const toast = useToast()
    const checkboxCOlor = useColorModeValue("gray.900", "white");
    const [selectedOption, setselectedOption] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const [state, setState] = useState({
        label: '',
        description: '',
        field_type: '',
        sort: '',
        options: [{ label: '', value: '' }],
    })
    const onChange = (e) => {
        const { name, value } = e.target
        let param = {
            ...state,
            [name]: value
        }
        if (name === 'field_type') {
            param = {
                ...param,
                options: [{ label: '', value: '' }],
            }
        }
        setState(param);
    }
    const handleOptionChange = (e, index) => {
        const { name, value } = e.target
        let param = {
            ...state,
        }
        param.options[index][name] = value
        if (name === 'label') {
            param.options[index]['value'] = value.replaceAll(' ', '_').toUpperCase()
        }
        setState(param)
    }
    const handleAddOption = () => {
        let param = {
            ...state,
        }
        param.options.push({ label: '', value: '' })
        setState(param)
    }
    console.log(state)
    const handleRemoveOption = (index) => {
        let param = {
            ...state,
            options: state.options.filter((item, key) => !selectedOption.includes(key))
        }
        setselectedOption([])
        setState(param)
    }
    const handleCheckbox = (e) => {
        const { value, checked } = e.target;
        let selectedItems = [...selectedOption]
        if (checked) {
            selectedItems.push(+value);
        } else {
            const index = selectedItems.indexOf(+value);
            if (index > -1) {
                selectedItems.splice(index, 1);
            }
        }
        setselectedOption(selectedItems)
    }
    const onSubmit = () => {
        setisLoading(true)
        Fetch('dashboard/template-property/', { ...state, options: state.options.filter(item => item.label?.length) }, { method: 'post' }).then(res => {
            setisLoading(false)
            if (res.status) {
                toast({
                    title: `Property created.`,
                    description: `We've created template property successfully.`,
                    status: 'success',
                    duration: 3000,
                    isClosable: false,
                })
              onClose(true)
            }
        })
    }
    let param = { ...state }
    delete param.options
    const { errors, handleSubmit, handleNewError } = FormC({
        values: param,
        onSubmit
    })
    return <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <form onSubmit={handleSubmit}>
            <ModalContent>
                <ModalHeader>Create a new property</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <InputField errorText={errors.label} label='Label' name='label' type='text' onChange={onChange} />
                    <InputField errorText={errors.description} label='Description' name='description' type='text' onChange={onChange} />
                    <InputField errorText={errors.field_type} label='Field type' name='field_type' type='select' onChange={onChange}>
                        <option value='' disabled selected>Select Field Type</option>
                        <option value='text'>Single line text</option>
                        <option value='textarea'>Multi-line text</option>
                        <option value='integer'>Number</option>
                        <option value='select'>Dropdown select</option>
                        <option value='multi_checkbox'>Multiple checkboxes</option>
                        <option value='datetime-local'>Date/time picker</option>
                    </InputField>

                    {
                        state.field_type === 'select' || state.field_type === 'multi_checkbox' ?
                            <>
                                <InputField errorText={errors.sort} label='Sort' name='sort' type='select' onChange={onChange}>
                                    <option value='' disabled selected>Select Sort</option>
                                    <option value='a-z'>Alphabetically</option>
                                    <option value='custom'>Custom</option>
                                </InputField>
                                <Box>
                                    <List>
                                        <ListItem py='10px' borderBottomWidth={1} borderBottomColor='#E2E8F0' display='flex'>
                                            <Checkbox
                                                _focus={{
                                                    boxShadow: "unset",
                                                    "& .chakra-checkbox__control": {
                                                        boxShadow: "unset",
                                                    }
                                                }}
                                                icon={<CustomIcon />}
                                                mr='10px'
                                                _checked={{
                                                    "& .chakra-checkbox__control": {
                                                        background: "rgba(173, 38, 180, 0.10)",
                                                        borderColor: "#AD26B4"
                                                    }
                                                }}
                                                size='lg'>
                                            </Checkbox>
                                            <Text width={'50%'} fontSize={13} color={'#9CA3AF'} textTransform='uppercase'>Label</Text>
                                            <Text width={'50%'} fontSize={13} color={'#9CA3AF'} textTransform='uppercase'>Internal value</Text>
                                        </ListItem>
                                        {
                                            state.options?.map((items, key) => <ListItem key={key} py='10px' borderBottomWidth={1} borderBottomColor='#E2E8F0' display='flex'>
                                                <Checkbox
                                                    _focus={{
                                                        boxShadow: "unset",
                                                        "& .chakra-checkbox__control": {
                                                            boxShadow: "unset",
                                                        }
                                                    }}
                                                    mr='10px'
                                                    _checked={{
                                                        "& .chakra-checkbox__control": {
                                                            background: "#AD26B4",
                                                            borderColor: "#AD26B4"
                                                        }
                                                    }}
                                                    isChecked={selectedOption.includes(key)}
                                                    onChange={handleCheckbox}
                                                    value={key}
                                                    size='lg'>
                                                </Checkbox>
                                                <Box width='50%' pr='15px'>
                                                    <InputField name='label' value={items.label} onChange={(e) => handleOptionChange(e, key)} mb='0px' />
                                                </Box>
                                                <Box width='50%'>
                                                    <InputField name='value' value={items.value} onChange={(e) => handleOptionChange(e, key)} mb='0px' />
                                                </Box>
                                            </ListItem>)
                                        }

                                    </List>
                                    <Flex py='10px' borderBottomWidth={1} borderBottomColor='#E2E8F0'>
                                        <Button background='transparent' _hover={{ background: 'transparent' }} _focus={{ boxShadow: 'unset' }} fontSize={'14px'} color='#334155' fontWeight={400} onClick={handleAddOption}><Image src={Add} mr='7px' /> Add option</Button>
                                        <Button background='transparent' _hover={{ background: 'transparent' }} _focus={{ boxShadow: 'unset' }} _disabled={{ opacity: 0.3 }} disabled={!selectedOption?.length} fontSize={'14px'} color='#334155' fontWeight={400} onClick={handleRemoveOption}><Image src={Remove} mr='7px' /> Clear selected</Button>
                                    </Flex>
                                </Box>
                            </>
                            : null
                    }
                    {state.label ?
                        <Box pt='15px'>
                            <FormText label='Preview' />
                            <Box borderWidth='1px' borderColor='#E2E8F0' borderRadius='8px' p='8px'>
                                {
                                    state.field_type === 'multi_checkbox' ?
                                        <>
                                            <FormText label={state.label} />
                                            {state.options?.map((items, key) => <Checkbox _focus={{
                                                boxShadow: "unset",
                                                "& .chakra-checkbox__control": {
                                                    boxShadow: "unset",
                                                }
                                            }}
                                                mr='10px'
                                                _checked={{
                                                    "& .chakra-checkbox__control": {
                                                        background: "#AD26B4",
                                                        borderColor: "#AD26B4"
                                                    }
                                                }}
                                                key={key}
                                                size='lg'>
                                                <Text fontSize='14px'>{items.label}</Text>
                                            </Checkbox>)}
                                        </>
                                        :
                                        <InputField label={state.label} type={state.field_type}>
                                            {
                                                state.options?.length ?
                                                    <>
                                                        <option value='' disabled selected>Select</option>
                                                        {
                                                            state.options?.map((items, key) => <option key={key} value={items.value}>{items.label}</option>)
                                                        }
                                                    </>
                                                    : null
                                            }

                                        </InputField>
                                }
                            </Box>
                        </Box> : null}
                </ModalBody>
                <ModalFooter>
                    <Button variant="outline" textColor={"#0584FE"} borderColor={"#0584FE"} mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button isLoading={isLoading} type='submit' colorScheme='blue' onClick={onSubmit}>Create</Button>
                </ModalFooter>
            </ModalContent>
        </form>

    </Modal>
}
function CustomIcon(props) {
    const { isIndeterminate, isChecked, ...rest } = props
    return (
        <>
            {isChecked ? (
                <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.83301 1H10.1663" stroke="#AD26B4" stroke-width="2" stroke-linecap="round" />
                </svg>
            ) : null}
        </>
    )
}
export default AddProperty